import getData from "@/__main__/get-data.mjs";
import fetchReactions from "@/game-fortnite/fetches/reactions.mjs";
import ItemsModel from "@/game-fortnite/models/items.mjs";
import validateFn from "@/game-fortnite/models/locker.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";
import clone from "@/util/clone.mjs";
import { devError } from "@/util/dev.mjs";

async function fetchLocker(profileId) {
  await getData(API.getItems(), ItemsModel, ["fortnite", "items"]);

  try {
    const locker = await getData(
      API.getLocker({ fortniteAccountId: profileId }),
      validateFn,
      ["fortnite", "locker", profileId],
    );

    if (!(locker instanceof Error) && locker.length > 0) {
      await fetchReactions(clone(locker));
    }
  } catch (error) {
    devError("Failed to fetch locker", error);
  }
}

export default fetchLocker;
